<template>
    <admin-dashboard-layout>
        <CustomInput
            label="About text"
            type="textarea"
            v-model="$store.getters.settings.about"
            @change="$store.dispatch('updateSettings')"
        />
    </admin-dashboard-layout>
</template>

<script>
export default {
    components: {
        AdminDashboardLayout: () => import("@/layouts/AdminDashboardLayout"),
    },
    name: "Settings",
    created() {
        this.$store.dispatch("setSettings");
    },
};
</script>

<style lang="scss" scoped>
</style>
